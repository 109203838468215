import React from 'react';
import Clients from '../components/Clients';
import ReadyToOrder from '../components/ReadyToOrder';
import Footer from '../components/Footer';
import Header from '../components/Header';
import About from '../components/About';
import Products from '../components/Products';

const Home = () => {
  return (
    <>
      <Header />
      <About />
      <Products />
      <ReadyToOrder />
      <Footer />
    </>
  );
};

export default Home;
