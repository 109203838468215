import React from 'react';
import sgf from '../images/clients/sgf.png';
import tnt from '../images/clients/tnt.png';
import lfm from '../images/clients/lfm.png';

const clientImage = {
  height: '10rem',
  width: 'auto',
  mixBlendMode: 'colorBurn',
};

const Clients = () => {
  return (
    <div className="mt-8 bg-gray-100">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl primary-text uppercase font-bold">
            Some of our clients.
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-[#4695CE]"></div>
          </div>
        </div>

        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <div className="grid sm:grid-cols-3 lg:grid-cols-3">
            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100 w-1/6"
            >
              <img src={sgf} alt="client" />
            </div>

            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100 w-1/6 "
            >
              <img src={tnt} alt="client" />
            </div>

            <div
              style={clientImage}
              className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-80 hover:opacity-100 w-1/6"
            >
              <img src={lfm} alt="client" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
